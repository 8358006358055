@font-face {
    font-family: "Inter";
    src: url("./Inter-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Inter Semi Bold";
    src: url("./Inter-SemiBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Inter Bold";
    src: url("./Inter-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}
